<template>
  <div class="container">
    <div class="formbox">
      <el-button
        class="btn_e6a23c"
        style="margin-bottom: 20px"
        @click="saveBtn()"
      >
        保 存
      </el-button>
      <el-button
        class="btn_e6a23c"
        style="margin-bottom: 20px"
        @click="saveBtn2()"
      >
        保存并审核
      </el-button>
      <el-form
        :inline="true"
        label-position="right"
        label-width="100px"
        :model="datas"
        class="demo-form-inline formDisplay"
      >
        <!-- <el-form-item label="单据编号:" class="queryItem1">
          <el-input
            v-model="datas.listCode"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="来源单号:" class="queryItem1">
          <el-input
            v-model="datas.documentNumber"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员号:" class="queryItem1">
          <el-input
            v-model="datas.memberId"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员名称:" class="queryItem1">
          <el-input
            v-model="datas.nickName"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="仓库:" class="queryItem1">
          <el-input
            v-model="datas.storageName"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="目的地邮编:" class="queryItem1">
          <el-input
            v-model="datas.postCode"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="目的地:" class="queryItem1">
          <el-input
            v-model="datas.country"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道:" class="queryItem1">
          <el-input
            v-model="datas.channelName"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="打包件数:" class="queryItem1">
          <el-input
            v-model="datas.sumCount"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="打包重量:" class="queryItem1">
          <el-input
            v-model="datas.sumWeight"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="打包体积:" class="queryItem1">
          <el-input
            v-model="datas.volume"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="材积除:" class="queryItem1">
          <el-input
            v-model="datas.divisor"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="材积:" class="queryItem1">
          <el-input
            v-model="datas.dataVolume"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="结算重量:" class="queryItem1">
          <el-input
            v-model="datas.billingWeight"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="标准运费:" class="queryItem1">
          <el-input
            v-model="datas.standardFree"
            placeholder=""
            disabled
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="转单号:" class="queryItem1">
          <el-input
            v-model="datas.channelNumber"
            placeholder=""
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道代理:" class="queryItem1">
          <el-select v-model="datas.agentId" placeholder="请选择" class="input">
            <el-option
              v-for="item in agentList"
              :key="item.customerAgentId"
              :label="item.agentName"
              :value="item.customerAgentId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="其他费用:"
          @click.native="otherDia"
          class="queryItem1 otherFee"
        >
          <el-input
            v-model="datas.otherFree"
            placeholder="请选择"
            readonly
            class="input"
          ></el-input>
        </el-form-item>

        <el-form-item label="修改运费:" class="queryItem1">
          <el-input
            v-model="datas.updateFreight"
            placeholder="请输入"
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item label="应收合计:" class="queryItem1">
          <el-input
            v-model="datas.settlementAmount"
            placeholder=""
            disabled
            class="input"
          ></el-input>
          <!-- </el-form-item>
          <el-form-item label="" class="queryItem1" style="border:none"> -->
        </el-form-item>
        <el-form-item
          label="打包备注:"
          class="queryTextare"
          style="width: 100%"
        >
          <el-input
            type="textarea"
            rows="2"
            v-model="datas.packComment"
            disabled
            class="inputtext"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="修改备注:"
          class="queryTextare"
          style="width: 100%"
        >
          <el-input
            type="textarea"
            rows="2"
            v-model="datas.comment"
            placeholder="请输入"
            class="inputtext"
          ></el-input>
        </el-form-item>
        <h5 style="color: #666">
          提示:只能修改 转单号,渠道代理,运费,其他费用以及备注!
        </h5>
      </el-form>
    </div>
    <!-- 展示费项 -->
    <el-dialog
      title="其他费用"
      :visible.sync="showOtherFee"
      width="50%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="formbox" style="width: 100%; min-width: 400px">
        <el-form
          :inline="true"
          label-position="right"
          label-width="100px"
          :model="formInline"
          class="demo-form-inline formDisplay"
        >
          <el-form-item label="应收客户:" class="queryItem1 queryItem_dia">
            <el-input
              v-model="datas.nickName"
              placeholder=""
              disabled
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="应收费项:" class="queryItem1 queryItem_dia">
            <el-select
              v-model="formInline.itemServiceId"
              placeholder="请选择"
              class="input"
              @change="changeService"
            >
              <el-option
                v-for="item in itemServiceList"
                :key="item.itemServiceId"
                :label="item.title"
                :value="item.itemServiceId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应收费用:" class="queryItem1 queryItem_dia">
            <el-input
              v-model.trim="formInline.amount"
              type="number"
              placeholder="请输入"
              class="input"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          class="btn_e6a23c"
          style="margin: 10px auto"
          @click="saveFeeBtn()"
        >
          添加费项
        </el-button>

        <!-- 费项 -->
        <div class="form_fq">
          <el-table :data="tableData2" border stripe style="width: 100%">
            <el-table-column
              fixed="left"
              label="序号"
              type="index"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="serviceName"
              label="应收费项"
              min-width="180"
            ></el-table-column>
            <el-table-column prop="amount" label="应收金额" min-width="100">
            </el-table-column>
            <!-- <el-table-column prop="isDelete" label="是否可以删除" min-width="100">
            </el-table-column> -->

            <el-table-column label="操作" min-width="180">
              <template slot-scope="scope">
                <el-button
                  class="btn_e6a23c formBtn"
                  style="margin-right: 10px"
                  @click="editBtn(scope.$index)"
                >
                  修改
                </el-button>
                <!-- <el-button class="formBtn" @click="delBtn(scope.$index)"
                  >移除
                </el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <div style="margin: 10px">费项合计:{{ allPrice || 0 }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改费项 -->
    <el-dialog
      title="修改费项"
      :visible.sync="showOtherFee2"
      width="55%"
      :before-close="handleClose2"
      :modal-append-to-body="false"
    >
      <div class="formbox" style="width: 100%; min-width: 400px; height: 40vh">
        <el-form
          :inline="true"
          label-position="right"
          label-width="100px"
          :model="editData"
          class="demo-form-inline formDisplay"
        >
          <el-form-item label="应收客户:" class="queryItem1 queryItem_dia">
            <el-input
              v-model="datas.nickName"
              placeholder=""
              disabled
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item label="应收费项:" class="queryItem1 queryItem_dia">
            <el-select
              v-model="editData.itemServiceId"
              placeholder="请选择"
              class="input"
              @change="changeService"
            >
              <el-option
                v-for="item in itemServiceList"
                :key="item.itemServiceId"
                :label="item.title"
                :value="item.itemServiceId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应收费用:" class="queryItem1 queryItem_dia">
            <el-input
              v-model.trim="editData.amount"
              type="number"
              placeholder="请输入"
              class="input"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showOtherFee2 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import MathNumBer from "@/assets/js/MathNumBer";
export default {
  components: {},
  inject: ["reload"],
  data() {
    return {
      datas: {
        documentNumber: "", //来源单号
        postCode: "", //邮编
        billingWeight: "", //结算重量
        sumWeight: "", //打包重量
        channelName: "",
        packComment: "", //打包备注
        comment: "", //修改备注
        country: "",
        divisor: "",
        dataVolume: "",
        volume: "", //打包体积
        sumCount: "", //
        standardFree: "", //
        nickName: "", //
        memberId: "", //
        otherFree: "", //
        // otherUnitPrice: "", //
        settlementAmount: "", //
        storageName: "", //
        channelNumber: "", //转单号
        agentName: "", //渠道代理
        agentId: "", //渠道代理id
        updateFreight: "", //修改运费
      },
      agentList: [],
      tableData2: [],
      itemServiceList: [],
      showOtherFee: false,
      showOtherFee2: false,
      formInline: {
        amount: "",
        financeServiceId: "",
        // isDelete: "1", //是否可以删除   0==否,1==是
        itemServiceId: "",
        serviceName: "",
      },
      editData: {
        amount: "",
        financeServiceId: "",
        itemServiceId: "",
        serviceName: "",
      },
      allPrice: 0,
      // financeInId: "", //应收id
      param_s: "", //传过来的参数
    };
  },
  watch: {
    tableData2: {
      handler(n, o) {
        let newArr = n;
        let sum = 0;
        newArr.forEach((item) => {
          sum = MathNumBer.add(sum, item.amount * 1 || 0); //精度计算
        });
        this.allPrice = MathNumBer.mathRound(sum, 3); //保留三位小数
      },
      deep: true,
    },
    "datas.updateFreight": {
      handler(n, o) {
        //修改运费
        this.datas.settlementAmount = MathNumBer.add(
          n * 1,
          this.datas.otherFree * 1
        );
      },
      deep: true,
    },
    "datas.otherFree": {
      handler(n, o) {
        //其他费用
        this.datas.settlementAmount = MathNumBer.add(
          n * 1,
          this.datas.updateFreight * 1
        );
      },
      deep: true,
    },
    // settlementAmount
  },
  created() {
    // let param = this.$route.query || "";
    // if (param) {
    //   this.param_s = param;
    //   this.getDetails();
    // }
    let param2 = {
      directionStatus: "shou:direction_status", //费项类型 shou:direction_status=收 fu:direction_status=付
      title: "", //费项名称
    };

    //获取应收费项
    Api.itemServiceListAll(param2).then((res) => {
      this.itemServiceList = res.data.result || [];
    });

    //渠道代理列表
    Api.channelAgentChooseList({ agentName: "" }).then((res) => {
      this.agentList = res.data.result || [];
    });
  },
  mounted() {},
  activated() {
    let param = this.$route.query || "";
    if (param.pageType && param.pageType == "newpage") {
      this.param_s = param;
      this.getDetails();
    }
  },
  methods: {
    //确定修改费项
    confirmBtn1() {
      let that = this;

      let param = {
        financeInId: this.param_s.financeInId,
        itemServices: this.tableData2,
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.updateFinanceInOtherService(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.allPrice = res.data.result.amount || 0;
          setTimeout(() => {
            this.showOtherFee = false;
            this.datas.otherFree = this.allPrice;
            // that.getDetails();
          }, 1000);
        }
      });
    },
    changeService(e) {
      console.log(e);
      this.itemServiceList.forEach((item) => {
        if (item.itemServiceId == e) {
          this.formInline.serviceName = item.title;
        }
      });
    },

    getDetails() {
      let p = {
        documentStatus:
          this.param_s.documentStatus || "bill_storepack_v1%3Adocument_status",
        financeInId: this.param_s.financeInId || "",
      };
      if (!p.financeInId) {
        return;
      }
      Api.financeStorepackDetail(this.param_s).then((res) => {
        let datas = res.data.result;
        this.datas = datas;
        console.log(this.datas);
      });
    },
    //新增费项
    saveFeeBtn() {
      if (!this.formInline.amount) {
        this.$message.warning("应收金额不能为空!");
        return;
      }
      if (!this.formInline.itemServiceId) {
        this.$message.warning("费项不能为空");
        return;
      }
      let obj = JSON.parse(JSON.stringify(this.formInline));
      this.tableData2.push(obj);
      this.formInline = {
        amount: "",
        financeServiceId: "",
        itemServiceId: "",
        serviceName: "",
      };
    },
    // 保存全部修改
    saveBtn() {
      let that = this;
      let param = {
        agentId: this.datas.agentId,
        channelNumber: this.datas.channelNumber,
        comment: this.datas.comment,
        financeInId: this.param_s.financeInId,
        otherFee: this.datas.otherFree,
        updateFreight: this.datas.updateFreight,
      };
      let sign = tools.getSign(param);

      param.sign = sign;
      Api.updateFinanceIn(param).then((res) => {
        this.$message.success(res.data.message || "保存成功");
        setTimeout(() => {
          tools.closePage();
          this.reload();
        }, 1000);
      });
    },
    // 保存并审核
    saveBtn2() {
      let that = this;
      let param = {
        agentId: this.datas.agentId,
        channelNumber: this.datas.channelNumber,
        comment: this.datas.comment,
        financeInId: this.param_s.financeInId,
        otherFee: this.datas.otherFree,
        updateFreight: this.datas.updateFreight,
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.updateFinanceIn(param).then((res) => {
        this.$message.success(res.data.message || "保存成功");

        // 审核
        let pdata = {
          examine: 0, //0==审核
          ids: [this.param_s.financeInId], //应收计费id 数组[]
        };
        Api.financeExamine(pdata).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "审核成功");
            setTimeout(() => {
              // that.closeTab();
              tools.closePage();
              this.reload();
            }, 1000);
          }
        });
      });
    },

    //关闭当前页面,跳转指定页面
    closeTab() {
      let arrs = JSON.parse(window.sessionStorage.editableTabs);
      arrs.forEach((item, index) => {
        if (item.path == "/Finance/modifyFee") {
          arrs.splice(index, 1);
        }
      });
      window.sessionStorage.setItem("editableTabs", JSON.stringify(arrs));
      this.$router.push("/Finance/ReceivableBilling");
      this.reload();
    },

    otherDia() {
      this.showOtherFee = true;
      Api.financeInOtherServiceList({
        financeInId: this.param_s.financeInId,
      }).then((res) => {
        this.tableData2 = res.data.result || [];
      });
    },
    handleClose() {
      this.showOtherFee = false;
    },
    handleClose2() {
      this.showOtherFee2 = false;
    },

    editBtn(e) {
      this.showOtherFee2 = true;
      this.editData = this.tableData2[e];
    },

    delBtn(e) {
      // console.log(e)
      this.$confirm("是否移除该费项?", "提示:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.tableData2.splice(e, 1);
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__footer {
    text-align: center;
  }
}
.formbox {
  width: 80%;
  min-width: 800px;
  margin: 20px auto;
  //   padding: 10px 30px;
  box-sizing: border-box;
  //   display: flex;

  .formDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    ::v-deep {
      .el-form-item {
        margin-bottom: 0;
      }
      .el-input.is-disabled .el-input__inner {
        color: #666;
      }
    }

    .beizhuBox {
      width: 100%;
      margin-top: 30px;
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 150px);
        }
      }
      // .inputText {
      //     width: calc(100% - 150px);
      // }
    }
    .otherFee {
      ::v-deep {
        .el-form-item__label {
          color: #f00;
        }
      }
    }

    .queryItem1 {
      // min-width: 300px;
      width: 30%;
      margin: 4px 0;
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      align-items: center;

      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 100px);
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-input__inner {
          line-height: 28px;
          height: 28px;
          border: none;
        }
        .el-form-item__label {
          line-height: 30px;
        }
        .el-select {
          width: 100%;
        }
      }

      .input {
        width: 100%;
        height: 30px;
      }
    }
    .queryItem_dia {
      width: 44%;
    }
    .queryTextare {
      width: calc(100% - 100px);
      display: flex;
      align-items: center;
      margin-top: 20px;
      border: 1px solid #d7d7d7;
      box-sizing: border-box;
      margin-right: 0;

      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 100px);
        }
        .el-textarea__inner {
          border: none;
        }
      }
    }
  }

  .form_fq {
    margin-top: 20px;
  }

  .inpu {
    width: 180px;
  }
  .inpu1 {
    width: 210px;
  }
}

.formbox ::v-deep {
  .el-button {
    padding: 7px 10px !important;
  }
}

.btn_e6a23c {
  background-color: #e6a23c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}
</style>
